<template lang="pug">
basicPages(:title="$t('contact.title')")
  p(v-html="$t('contact.description')")
  .email {{ $t('private_info.mail') }}
  p(v-html="$t('contact.text1')") 
</template>

<script>
import basicPages from "@/components/structure/views/basicPages";
export default {
  name: "Contacto",
  components: {
    basicPages,
  },
};
</script>

<style lang="scss" scoped>
.email {
  @apply text-xl my-5 font-bold text-blue-400 md:text-3xl;
}
</style>
